<template>
  <div class="MyHeader-container">
    <div class="main">
      <img
        class="logo"
        src="@/assets/images/logo_1@3x.png"
        alt=""
        @click="goHome"
      />
      <div v-if="!isNoLogin" class="tab">
        <router-link tag="div" class="item" to="/home">首页</router-link>
        <router-link tag="div" class="item" to="/complaint">投诉</router-link>
        <router-link tag="div" class="item" to="/feedback"
          >意见反馈</router-link
        >
      </div>
      <div v-if="!isNoLogin" class="user">
        <img
          class="avatar cur"
          src="@/assets/images/icon_ht@2x.png"
          alt=""
          @click="isPop = !isPop"
        />
        <span class="nickname cur" @click="isPop = !isPop">{{
          $store.state.name
        }}</span>
        <img
          class="arrow"
          :class="{ 'arrow-rotate': isPop }"
          src="@/assets/images/icon_xx@2x.png"
          alt=""
          @click="isPop = !isPop"
        />
        <transition name="el-zoom-in-top">
          <div class="pop" v-if="isPop">
            <div class="btn" @click="updatePass">修改密码</div>
            <div class="btn" @click="updatelog">修改日志</div>
            <div class="btn" @click="loginOut">
              <img src="@/assets/images/icon_tc@2x.png" alt="" />
              <span>退出</span>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!--修改密码弹窗-->
    <updateLog ref="updateLog"></updateLog>
    <UpdatePassDialog ref="updatePassDialog"></UpdatePassDialog>
  </div>
</template>

<script>
import UpdatePassDialog from "@/components/dialogs/UpdatePassDialog";
import updateLog from "@/components/dialogs/updateLog";

export default {
  name: "MyHeader",
  data() {
    return {
      isPop: false,
    };
  },
  components: { UpdatePassDialog, updateLog },
  props: {
    isNoLogin: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async loginOut() {
      this.$store.commit("LOGOUT");
      this.isPop = false;
      await this.$router.push("/login");
    },
    updatePass() {
      this.isPop = false;
      this.$refs.updatePassDialog.open();
    },
    updatelog() {
      this.isPop = false;
      this.$refs.updateLog.open();
    },
    goHome() {
      if (!this.isNoLogin) {
        this.$router.push("/home");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.MyHeader-container {
  height: 96px;
  background: #ffffff;
  box-shadow: 0 1px 0 1px $color-primary;

  .main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative;

    .logo {
      width: 166px;
      height: 69px;
      @include cv;
      left: 0;
    }

    .tab {
      @include cc;
      height: 100%;

      .item {
        &:last-child {
          margin-right: 0;
        }

        cursor: pointer;
        float: left;
        height: 100%;
        line-height: 96px;
        text-align: center;
        margin-right: 82px;

        font-size: 18px;
        color: #333333;
      }

      .router-link-active {
        border-bottom: 3px solid $color-primary;
        color: $color-primary;
      }
    }

    .user {
      @include cv;
      right: 0;
      z-index: 999;

      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 10px;
      }

      .nickname {
        font-size: 16px;
        color: #333333;
        vertical-align: middle;
        margin-right: 22px;
        min-width: 100px;
      }

      .arrow {
        cursor: pointer;
        width: 20px;
        vertical-align: middle;
        transform: rotateZ(0deg);
        transition: all 0.5s;
      }

      .arrow-rotate {
        width: 20px;
        transform: rotateZ(-180deg);
        transition: all 0.5s;
      }

      .pop {
        z-index: 9999;
        position: absolute;
        bottom: -130px;
        right: 0;
        width: 353px;
        height: 100px;
        background: #ffffff;
        box-shadow: 0 0 5px 1px rgba(168, 168, 168, 0.25);
        .btn {
          cursor: pointer;
          width: 100px;
          height: 40px;
          background: $color-primary;
          text-align: center;
          line-height: 40px;
          font-size: 16px;
          color: #ffffff;
          @include cv;

          &:first-child {
            left: 16px;
          }
          &:nth-child(2) {
            left: 127px;
          }
          &:last-child {
            right: 16px;
          }

          img {
            width: 27px;
            vertical-align: middle;
            margin-right: 5px;
          }

          span {
            height: 40px;
            vertical-align: middle;
          }
        }
      }

      .pop::before {
        content: "";
        position: absolute;
        top: -18px;
        right: 13.5%;
        display: block;
        width: 0;
        height: 0;
        border: 9px solid transparent;
        border-bottom-color: #f1ecec;
      }

      .pop::after {
        content: "";
        position: absolute;
        top: -15px;
        right: 14%;
        display: block;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-bottom-color: white;
      }
    }
  }
}
</style>
