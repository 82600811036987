const userApi = {
  //登录
  login: { method: "post", url: "/api/user/login" },
  // 是否启用短信登录
  msgEnable: { method: "post", url: "/api/renlian/config" },

  //修改密码
  resetPwd: { method: "post", url: "/api/user/resetpwd" },
  // 获取验证码
  getCode: { method: "post", url: "/api/user/send" },
  // 获取验证码
  getResetCode: { method: "post", url: "/api/user/retrieve_password_yzm" },
  // 忘记密码
  resetPsd: { method: "post", url: "/api/user/retrieve_password" },
  //判断是否需要查看对账
  report: { method: "post", url: "/api/index/report" },
  //查看
  configure: { method: "post", url: "/api/index/configure" },
  //添加查看对账结果
  addReport: { method: "post", url: "/api/index/addReport" },
};

export default userApi;
