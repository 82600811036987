<template>
  <div id="app">
    <MyHeader :is-no-login="$route.meta.isNoLogin"></MyHeader>
    <router-view></router-view>
    <MyFooter></MyFooter>
  </div>
</template>
<script>

import MyHeader from "@/components/MyHeader";
import MyFooter from "@/components/MyFooter";

export default {
  components: {MyHeader, MyFooter}
};
</script>
<style lang="scss" scoped>
#app {
  font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  color: #333333;
  background-color: #FFFFFF;
}
</style>
