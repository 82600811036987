const getters = {
  //获取选中公司的id
  // getCompanyId: state => {
  //   if (state.company.id == 0) {
  //     return state.company.apply_id;
  //   } else if (state.company.apply_id == 0) {
  //     return state.company.id;
  //   } else {
  //     return null;
  //   }
  // }
};
export default getters;