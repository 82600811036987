const state = {
  token: window.sessionStorage.getItem("token"),
  name: window.sessionStorage.getItem("name"),
  mobile: window.sessionStorage.getItem("mobile"),
  isUpdatePwd: window.sessionStorage.getItem("isUpdatePwd"),
  avatar: window.sessionStorage.getItem("avatar"),
  selectDays: window.sessionStorage.getItem("selectDays"),
  webMobile: window.sessionStorage.getItem("webMobile"),
  webTell: window.sessionStorage.getItem("webTell"),
  keep: window.sessionStorage.getItem("keep"),//作废
};

export default state;