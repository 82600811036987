const mutations = {

  SET_TOKEN: (state, value) => {
    state.token = value;
    window.sessionStorage.setItem("token", value);
  },
  SET_AVATAR: (state, value) => {
    state.avatar = value;
    window.sessionStorage.setItem("avatar", value);
  },
  SET_NAME: (state, value) => {
    state.name = value;
    window.sessionStorage.setItem("name", value);
  },

  SET_MOBILE: (state, value) => {
    state.mobile = value;
    window.sessionStorage.setItem("mobile", value);
  },
  SET_IS_UPDATE_PWD: (state, value) => {
    state.isUpdatePwd = value;
    window.sessionStorage.setItem("isUpdatePwd", value);
  },
  SET_WEBMOBILE: (state, value) => {
    state.webMobile = value;
    window.sessionStorage.setItem("webMobile", value);
  },
  SET_WEBTELL: (state, value) => {
    state.webTell = value;
    window.sessionStorage.setItem("webTell", value);
  },
  SET_KEEP: (state, value) => {
    state.keep = value;
    window.sessionStorage.setItem("keep", value);
  },
  SET_SELECT_DAYS: (state, value) => {
    state.selectDays = value;
    window.sessionStorage.setItem("selectDays", value);
  },


  LOGOUT: (state) => {
    state.token = null;
    state.name = null;
    state.mobile = null;
    state.isUpdatePwd = null;
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("name");
    window.sessionStorage.removeItem("mobile");
    window.sessionStorage.removeItem("isUpdatePwd");
    window.sessionStorage.removeItem("selectDays");
  },



};

export default mutations;