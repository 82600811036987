import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/scss/index.scss';
import Vuex from 'vuex';

import * as requests from '@/plugins/axios/index'
import api from '@/api/index.js'
import { Loading } from "element-ui";


Vue.prototype.$request = requests.request
Vue.prototype.$intactRequest = requests.intactRequest
Vue.prototype.$api = api

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(Vuex);

Vue.prototype.Loading = "";
Vue.prototype.getNowFormatDate = function() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
};

// 显示loading
Vue.prototype.showLoading = function(msg) {
  Vue.prototype.Loading = Loading.service({
    fullscreen: true,
    target: "document.body",
    lock: true,
    text: msg||"加载中",
    background: "rgba(255, 255, 255, .8)"
  });
};
// 关闭 loading
Vue.prototype.closeLoading = function() {
  Vue.prototype.Loading.close();
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
