/*
 * 路由表
 * */
const routes = [
    {
        path: "/",
        redirect: "/login", //设置默认指向的路径
    },
    {
        path: /*录页*/ "/login",
        name: "login",
        meta: {isNoLogin: true,title:"施可丰"},
        component: () => import("@/views/Login"),
    },
    {
        path: /*首页*/ "/home",
        name: "home",
        component: () => import("@/views/Home"),
        redirect: "/home/list",
        children:[
            {
                path: /*列表*/ "list",
                name: "list",
                component: () => import("@/views/home/List"),
            },
            {
                path: /*详情*/ "detail",
                name: "detail",
                component: () => import("@/views/home/Detail"),
            },
        ]
    },
    {
        path: /*投诉*/ "/complaint",
        name: "complaint",
        component: () => import("@/views/Complaint"),
    },
    {
        path: /*反馈*/ "/feedback",
        name: "feedback",
        component: () => import("@/views/Feedback"),
    },
];
export default routes;
