const commonApi = {
  //首页余额记录
  getIndex: { method: "post", url: "/api/index/index" },
  //获取账单记录
  getBill: { method: "post", url: "/api/index/bill2" },
  //意见反馈
  addFeedback: { method: "post", url: "/api/feedback/addFeedback" },
  //投诉
  addComplain: { method: "post", url: "/api/feedback/addComplain" },
  //导出账单记录
  excel: { method: "get", url: "/api/index/excel" },
  // 修改密码日志
  updateLog: { method: "get", url: "/api/user/update_password_list" },
};

export default commonApi;
