<template>
<div class="MyFooter-container">
  <p>网站备案号：鲁ICP备11019782号</p>
  <p style="cursor:pointer;" @click="open">工信部备案网站查询链接：https://beian.miit.gov.cn/</p>
</div>
</template>

<script>
export default {
name: "MyFooter",
data() {
    return {
      keep: this.$store.state.keep,
    };
  },
  components:{},
  props:{},
  created() {
    console.log(this.$store.state.keep,'dddddddddcreatedcreated')

  },
  methods:{
  open(){
    window.open('https://beian.miit.gov.cn/')
  }
  }
}
</script>

<style lang="scss" scoped>
.MyFooter-container{
  height: $footer-height;
  background: url("../assets/images/bg_img_5@2x.png");
  background-size: auto 100%;

  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px 0;
}
</style>