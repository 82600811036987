<template>
  <el-dialog :visible.sync="dialogVisible" :show-close="false" width="629px">
    <div slot="title">修改日志</div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="uid" label="id" width="180"> </el-table-column>
      <el-table-column prop="phone" label="用户" width="180"> </el-table-column>
      <el-table-column prop="update_time" label="修改时间"> </el-table-column>
    </el-table>
    <div class="btn-group">
      <div class="btn" @click="qu">取消</div>
      <div class="btn" @click="qu">确认</div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ReminderDialog",
  data() {
    return {
      dialogVisible: false,
      tableData: [],
    };
  },
  components: {},
  props: {},

  methods: {
    async list() {
      let res = await this.$api.updateLog();
      console.log(res);
      if (res.code == 1) {
        this.tableData = res.data;
      }
    },
    open() {
      this.dialogVisible = true;
      this.list();
    },
    qu() {
      this.dialogVisible = false;
      // this.$parent.say();
      this.$store.commit("LOGOUT");
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog .el-dialog__header {
  background-color: $color-primary !important;
  padding: 0;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #ffffff;
}
.el-dialog__body {
  .form {
    .form-item {
      width: 318px;
      height: 40px;
      background: #f8f8f8;
      border: 1px solid #dadada;
      margin: 0 auto 19px;
      padding: 0 15px;
      input {
        width: 100%;
        height: 100%;
        padding: 0;
        outline: none;
        border: none;
        background-color: transparent;
      }
    }
  }
  .btn-group {
    margin: 30px auto;
    width: 318px;
    overflow: hidden;
    .btn {
      cursor: pointer;
      width: 145px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      &:first-child {
        float: left;
        background-color: #dadada;
        color: black;
      }
      &:nth-child(2) {
        float: right;
        background-color: $color-primary;
        color: white;
      }
    }
  }
}
</style>
