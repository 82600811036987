import Vue from "vue";
import VueRouter from "vue-router";
import { request } from "@/plugins/axios";
import store from "@/store/index";
import routes from "@/router/routes";
import { Message } from "element-ui";

Vue.use(VueRouter);

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  base: "/",
  routes,
  mode: "hash",
});

//路由守卫
router.beforeEach((to, from, next) => {

  window.document.title = "施可丰-对账"
  // 路由变化时取消当前所有非全局的pending状态的请求
  request.clearPendingPool();

  const token = store.state.token
    ? store.state.token
    : window.sessionStorage.getItem("token");


  //需要用户身份验证的页面
  if (!token&&!(to.matched.some((record) => record.meta.isNoLogin))) {
    //未通过
    next({ path: "/login", query: { redirect: to.fullPath } });
  } else {
    //通过
    next();
  }
});

router.onError((err) => {
  console.log(err);
  Message({
    type: "warning",
    message: err || "导航出错啦",
    duration: 1500,
  });
});

export default router;

