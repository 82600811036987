<template>
  <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      width="429px">
   <div slot="title">修改密码</div>
    <div class="form">
      <div class="form-item">
        <input v-model="oldPassword" type="password" placeholder="输入原始密码">
      </div>
      <div class="form-item">
        <input v-model="newPassword1" type="password" placeholder="输入新密码">
      </div>
      <div class="form-item">
        <input v-model="newPassword2" type="password" placeholder="再次输入新密码">
      </div>
    </div>
    <div class="btn-group">
      <div class="btn" @click="qu">取消</div>
      <div class="btn" @click="resetPwd">确认修改</div>
    </div>

  </el-dialog>
</template>

<script>
export default {
  name: "ReminderDialog",
  data() {
    return {
      dialogVisible: false,
      oldPassword:"",
      newPassword1:"",
      newPassword2:"",
    };
  },
  components: {},
  props: {},
  methods: {
    open() {
      this.dialogVisible = true;
    },
   qu(){
       this.dialogVisible = false;
      // this.$parent.say();
       this.$store.commit("LOGOUT");
   },
    close() {
      this.dialogVisible = false;

    },
    async resetPwd() {
      if (!this.oldPassword) return this.$message.error("请输入原始密码")
      if (!this.newPassword1) return this.$message.error("请输入新密码")
      if (!this.newPassword2) return this.$message.error("请再次输入新密码")
      if (this.newPassword1!=this.newPassword2) return this.$message.error("两次密码不一致")
      this.showLoading("请求中...");
      let res = await this.$api.resetPwd({
        newpassword: this.newPassword1,
        oldpassword: this.oldPassword,
      });
      this.closeLoading();
      if (res.code == 1) {
        this.$message.success(res.msg);
        this.dialogVisible=false
        this.$parent.say(this.newPassword1);
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog .el-dialog__header{
  background-color: $color-primary !important;
  padding: 0;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: #FFFFFF;
}
.el-dialog__body{
  .form{
    .form-item{
      width: 318px;
      height: 40px;
      background: #F8F8F8;
      border: 1px solid #DADADA;
      margin: 0 auto 19px;
      padding: 0 15px;
      input{
        width: 100%;
        height: 100%;
        padding: 0;
        outline: none;
        border: none;
        background-color: transparent;
      }
    }
  }
  .btn-group{
    margin: 30px auto;
    width: 318px;
    overflow: hidden;
    .btn{
      cursor: pointer;
      width: 145px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      &:first-child{
        float: left;
        background-color: #DADADA;
        color: black;
      }
      &:nth-child(2){
        float: right;
        background-color: $color-primary;
        color: white;
      }
    }
  }
}
</style>